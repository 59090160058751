import { defineStore } from "pinia"
import { MeiliSearch } from "meilisearch"
import stringSimilarity from "string-similarity"
import { sortObjByKey } from "@/libs/helpers.js"
import axios from "@/app/axios.js"

/**
 * Vuex store setup
 */
if (window.globalJs.translations === undefined) {
    window.globalJs = {
        route: window.translationsData.global_route,
        translations: window.translationsData.translations,
        axios: axios,
    }
}

export const useTutkitStore = defineStore("tutkit", {
    state: () => ({
        lang: window.globalJs.route.locale,
        t: window.globalJs.translations.frontend,
        search: {
            meiliClient: null,
            meiliApiKey: "89a221e76e5597dc93f8a97aaa741e6b9468e3b7fdd86f0ba69449cca161dacf",
            routes: {
                meiliSearch: "",
                userSpecial: "",
                searchPage: new URL(window.globalJs.route.search_index).pathname,
                productsData: new URL(window.globalJs.route.search_index_post).pathname,
            },
            resetButtonVisible: false,
            type: "all",
            pageLoadQuery: "",
            types: {
                all: window.globalJs.translations.frontend.search.tabs_all,
                packages: window.globalJs.translations.frontend.search.tabs_packages,
                content: window.globalJs.translations.frontend.search.tabs_content,
                software_categories: window.globalJs.translations.frontend.search.tabs_software_categories,
                blog_posts: window.globalJs.translations.frontend.search.tabs_blog_posts,
                help_articles: window.globalJs.translations.frontend.search.tabs_help_articles,
            },
            query: "",
            activeTab: "all",
            suggestions: {
                show: false,
                items: [],
                active: false,
                limit: 5,
            },
            allResults: 0,
            defaultTotalHits: {
                packages: 0,
                content: 0,
            },
            result: {
                packages: {
                    hits: [],
                    estimatedTotalHits: 0,
                },
                content: {
                    hits: [],
                    estimatedTotalHits: 0,
                },
                software_categories: {
                    hits: [],
                    estimatedTotalHits: 0,
                },
                blog_posts: {
                    hits: [],
                    estimatedTotalHits: 0,
                },
                help_articles: {
                    hits: [],
                    estimatedTotalHits: 0,
                },
            },
            showCardsInTab: {
                all: 6,
                single: 100,
            },
            serverResponse: {
                user: {},
                contents: {},
                suggestions: {},
            },
            sortBy: {
                active: "relevance",
                possible: {
                    relevance: window.globalJs.translations.frontend.search.sort_relevance,
                    date_order: window.globalJs.translations.frontend.search.sort_date_order,
                    title: window.globalJs.translations.frontend.search.sort_title,
                },
            },
            filters: {
                type: {
                    active: "all",
                    possible: {
                        all: window.globalJs.translations.frontend.search.filter_type_all,
                        tutorials:
                            window.globalJs.route.locale === "de"
                                ? window.globalJs.translations.frontend.search.filter_type_tutorials
                                : null,
                        assets: window.globalJs.translations.frontend.search.filter_type_assets,
                        templates: window.globalJs.translations.frontend.search.filter_type_templates,
                    },
                },
                software: {
                    active: "All",
                    text: window.globalJs.translations.frontend.search.filters_software,
                    type: "radio",
                    search: "",
                    regexr: new RegExp("", "i"), // /search+/i
                },
                tutorials: {
                    text: window.globalJs.translations.frontend.search.filters_tutorials,
                    type: "checkbox",
                },
                assets: {
                    text: window.globalJs.translations.frontend.search.filters_assets,
                    type: "checkbox",
                },
                templates: {
                    text: window.globalJs.translations.frontend.search.filters_templates,
                    type: "checkbox",
                },
                topics: {
                    text: window.globalJs.translations.frontend.search.filters_topics,
                    type: "checkbox",
                },
            },
            facets: {
                active: "all",
                all: {},
                packages: {},
                content: {},
            },
            facetFilters: {
                all: {
                    type: [],
                },
                packages: {
                    software: [],
                    tutorials: [],
                    assets: [],
                    templates: [],
                    topics: [],
                },
                content: {
                    software: [],
                    tutorials: [],
                    assets: [],
                    templates: [],
                    topics: [],
                },
            },
            meiliSearchQueryConfig: {
                suggestions: {
                    limit: 100,
                    filter: [`language = ${window.globalJs.route.locale}`],
                },
                contents: {
                    packages: {
                        limit: 100,
                        filter: [],
                        sort: [],
                        facets: ["type", "software", "tutorials", "assets", "templates", "topics"],
                    },
                    content: {
                        limit: 100,
                        filter: [],
                        sort: [],
                        facets: ["type", "software", "tutorials", "assets", "templates", "topics"],
                    },
                    software_categories: {
                        limit: 100,
                        filter: [],
                    },
                    blog_posts: {
                        limit: 100,
                        filter: [],
                    },
                    help_articles: {
                        limit: 100,
                        filter: [],
                    },
                },
            },
            texts: {
                showMore: window.globalJs.translations.frontend.search.show_more,
            },
            temp: {
                contentTitle: "",
            },
        },
        modelsExtraData: {
            modelIds: [],
        },
    }),
    getters: {
        filterTypePossibleOptions: (state) => {
            return state.search.filters.type.possible
        },
        facetType: (state) => {
            return state.search.facets[state.search.facets.active].type
        },
        facetSoftware: (state) => {
            return state.search.facets[state.search.facets.active].software
        },
        facetTutorials: (state) => {
            return state.search.facets[state.search.facets.active].tutorials
        },
        facetAssets: (state) => {
            return state.search.facets[state.search.facets.active].assets
        },
        facetTopics: (state) => {
            return state.search.facets[state.search.facets.active].topics
        },
        facetTemplates: (state) => {
            return state.search.facets[state.search.facets.active].templates
        },
        isProductsTab: (state) => {
            return state.search.activeTab === "packages" || state.search.activeTab === "content"
        },
        activeSoftwareFacet: (state) => {
            let softwareActiveTab = "All"
            if (
                state.search.activeTab === "all" ||
                state.search.activeTab === "packages" ||
                state.search.activeTab === "content"
            ) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        state.search.facetFilters[state.search.activeTab],
                        "software",
                    ) &&
                    state.search.facetFilters[state.search.activeTab].software.length > 0
                ) {
                    softwareActiveTab = state.search.facetFilters[state.search.activeTab].software[0]
                }
            }
            return softwareActiveTab
        },
        getExampleArray: (state) => {
            return state.modelsExtraData.exampleArray
        },
    },
    actions: {
        defineMeiliSearchClient(meiliSearchClient) {
            this.search.meiliClient = meiliSearchClient
        },

        defineMeiliSearchRoute(meiliSearchClient) {
            this.search.routes.meiliSearch = meiliSearchClient
        },

        updatePageLoadQuery(query) {
            this.search.pageLoadQuery = query
            this.search.query = query
        },

        updateSearchSuggestions(newSearchSuggestions) {
            // Prepare query words
            let cleanedQuery = this.search.query.replace(/[^\u00C0-\u017FA-Za-z0-9]/g, " ").toLowerCase()
            let explodedQuery = cleanedQuery.split(" ")
            let words = []

            explodedQuery.forEach((queryWord, index) => {
                words[index] = []
                newSearchSuggestions.hits.forEach((hit) => {
                    let explodedTitle = hit.suggestion
                    let similarity = stringSimilarity.findBestMatch(queryWord, explodedTitle)
                    let bestSimilarity = similarity.bestMatch.target
                    explodedQuery.forEach((value, index) => {
                        if (typeof similarity.ratings[index] !== "undefined") {
                            if (index > 0 && bestSimilarity !== similarity.ratings[index].target) {
                                bestSimilarity = (bestSimilarity + " " + similarity.ratings[index].target).trimStart()
                            }
                        }
                    })
                    if (!words[index].includes(bestSimilarity) && bestSimilarity !== "") {
                        words[index].push(bestSimilarity)
                    }
                })
            })
            words = words.flat()
            this.search.suggestions.items = words
        },

        updateExtraSuggestions(data) {
            // Prepare query words
            let cleanedQuery = data.extraQuery.replace(/[^\u00C0-\u017FA-Za-z0-9]/g, " ").toLowerCase()
            let explodedQuery = cleanedQuery.split(" ")
            let words = []

            explodedQuery.forEach((queryWord, index) => {
                words[index] = []
                data.extraSuggestions.hits.forEach((hit) => {
                    let explodedTitle = hit.suggestion
                    let similarity = stringSimilarity.findBestMatch(queryWord, explodedTitle)
                    let bestSimilarity = similarity.bestMatch.target
                    explodedQuery.forEach((value, index) => {
                        if (typeof similarity.ratings[index] !== "undefined") {
                            if (index > 0 && bestSimilarity !== similarity.ratings[index].target) {
                                bestSimilarity = (bestSimilarity + " " + similarity.ratings[index].target).trimStart()
                            }
                        }
                    })
                    if (!words[index].includes(bestSimilarity) && bestSimilarity !== "") {
                        words[index].push(bestSimilarity)
                    }
                })
            })
            words = words.flat()
            this.search.suggestions.items = this.search.suggestions.items.concat(words)
            // this.search.suggestions.items = words;
        },

        changeSearchTab(tabName) {
            if (this.search.activeTab === tabName) {
                this.search.activeTab = "all"
            } else {
                this.search.activeTab = tabName
            }

            if (tabName === "all" || tabName === "packages" || tabName === "content") {
                this.search.facets.active = tabName
            }
            window.scrollTo(0, 0)

            this.changeSoftwareSearch("")
        },

        changeSoftwareSearch(softwareSearch) {
            this.search.filters.software.search = softwareSearch
            if (this.search.filters.software.search !== "") {
                this.search.filters.software.regexr = new RegExp(`${softwareSearch}+`, "i") //`/(${softwareSearch})+/i`
            }
        },

        changeActiveSuggestion(suggestionIndex) {
            this.search.suggestions.active = suggestionIndex
        },

        showSearchSuggestions(boolShow) {
            this.search.suggestions.show = boolShow
        },

        updateSearchQuery(newSearchQuery) {
            this.search.query = newSearchQuery
        },

        updateSearchResult(newSearchResult) {
            this.search.result = newSearchResult
            let resultsSum = 0
            const contentFacets = this.search.facetFilters.content
            const facetsActive = Object.values(contentFacets).some((array) => array.length > 0)
            const facetsTypeActive = this.search.facetFilters.all.type.length > 0

            for (const [key, value] of Object.entries(newSearchResult)) {
                if (key === "content" && this.search.query === "" && !facetsActive && !facetsTypeActive) {
                    this.search.result.content.estimatedTotalHits = this.search.defaultTotalHits.content
                }
                resultsSum = resultsSum + value.estimatedTotalHits
                this.search.allResults = resultsSum
            }
        },

        updateDefaultContentTotalHits(newDefaultTotalHits) {
            this.search.defaultTotalHits.content = parseInt(newDefaultTotalHits)
        },

        updateShowMoreResults(newShowMoreData) {
            this.search.result[newShowMoreData.type].hits.push(...newShowMoreData.hits)
        },

        updateSearchType(newSearchType) {
            this.searchType = newSearchType
        },

        // resetSearch() {
        //     Object.assign(getDefaultState());
        // },

        updateActiveFacets(facetsData) {
            let activeFacets
            if (facetsData.category === "type") {
                // Facet filter: Type
                this.search.meiliSearchQueryConfig.contents.packages.filter =
                    this.search.meiliSearchQueryConfig.contents.packages.filter.slice(10)
                this.search.meiliSearchQueryConfig.contents.content.filter =
                    this.search.meiliSearchQueryConfig.contents.content.filter.slice(10)
                if (facetsData.name !== "all") {
                    activeFacets = [facetsData.name]
                    this.search.facetFilters.all.type = activeFacets
                    this.search.meiliSearchQueryConfig.contents.packages.filter.unshift(`type = ${activeFacets}`)
                    this.search.meiliSearchQueryConfig.contents.content.filter.unshift(`type = ${activeFacets}`)
                } else {
                    this.search.facetFilters.all.type = []
                }
                // Reset all facets
                for (const [categoryName] of Object.entries(this.search.facetFilters.packages)) {
                    this.search.facetFilters.packages[categoryName] = []
                    this.search.facetFilters.content[categoryName] = []
                }

                return
            } else if (facetsData.category === "software") {
                // Facet filter: Software
                // Reset all facets
                for (const [categoryName] of Object.entries(this.search.facetFilters.packages)) {
                    this.search.facetFilters.packages[categoryName] = []
                    this.search.facetFilters.content[categoryName] = []
                }
                if (facetsData.name === "All") {
                    this.search.facetFilters[facetsData.tab].software = []
                } else {
                    activeFacets = [facetsData.name]
                    this.search.filters.software.active = facetsData.name
                    this.search.facetFilters[facetsData.tab].software = activeFacets
                }
            } else {
                activeFacets = [facetsData.name]
                if (this.search.facetFilters[facetsData.tab][facetsData.category].includes(facetsData.name)) {
                    const facetIndex = this.search.facetFilters[facetsData.tab][facetsData.category].indexOf(
                        facetsData.name,
                    )
                    if (facetIndex > -1) {
                        this.search.facetFilters[facetsData.tab][facetsData.category].splice(facetIndex, 1)
                    }
                } else {
                    this.search.facetFilters[facetsData.tab][facetsData.category].push(facetsData.name)
                }
            }
            // Generate facets query code
            let checkboxFacets = ["tutorials", "assets", "templates", "topics"]
            let facetQueryCodes = {
                packages: [],
                content: [],
            }
            // Type
            if (this.search.facetFilters.all.type.length > 0) {
                facetQueryCodes.packages.push([`type = '${this.search.facetFilters.all.type[0]}'`])
                facetQueryCodes.content.push([`type = '${this.search.facetFilters.all.type[0]}'`])
            }
            // Software
            if (this.search.facetFilters.packages.software.length > 0) {
                facetQueryCodes.packages.push([`software = '${this.search.facetFilters.packages.software[0]}'`])
            }
            if (this.search.facetFilters.content.software.length > 0) {
                facetQueryCodes.content.push([`software = '${this.search.facetFilters.content.software[0]}'`])
            }
            // All other facets
            checkboxFacets.forEach((facetGroup) => {
                let packagesCheckboxFacets = []
                let contentCheckboxFacets = []

                if (this.search.facetFilters.packages[facetGroup].length > 0) {
                    this.search.facetFilters.packages[facetGroup].forEach((facetName) => {
                        packagesCheckboxFacets.push(`${facetGroup} = '${facetName}'`)
                    })
                }
                if (packagesCheckboxFacets.length > 0) {
                    facetQueryCodes.packages.push(packagesCheckboxFacets)
                }

                if (this.search.facetFilters.content[facetGroup].length > 0) {
                    this.search.facetFilters.content[facetGroup].forEach((facetName) => {
                        contentCheckboxFacets.push(`${facetGroup} = '${facetName}'`)
                    })
                }
                if (contentCheckboxFacets.length > 0) {
                    facetQueryCodes.content.push(contentCheckboxFacets)
                }
            })
            if (facetQueryCodes[facetsData.tab].length > 0) {
                this.search.meiliSearchQueryConfig.contents[facetsData.tab].filter = facetQueryCodes[facetsData.tab]
            } else {
                delete this.search.meiliSearchQueryConfig.contents[facetsData.tab].filter
            }
        },

        updateSortBy(newActiveSortBy) {
            this.search.sortBy.active = newActiveSortBy
        },

        updateFacetsLists(facetsLists) {
            let allFacets = {}
            if (facetsLists.packages) {
                this.search.facets.packages = facetsLists.packages
                for (const [categoryName, subcategories] of Object.entries(facetsLists.packages)) {
                    let orderedFacets = sortObjByKey(subcategories)
                    this.search.facets.packages[categoryName] = orderedFacets
                }
            }
            if (facetsLists.content) {
                this.search.facets.content = facetsLists.content
                for (const [categoryName, subcategories] of Object.entries(facetsLists.content)) {
                    let orderedFacets = sortObjByKey(subcategories)
                    this.search.facets.content[categoryName] = orderedFacets
                }
            }
            if (facetsLists.packages && facetsLists.content) {
                // Get list with all keys from both parts
                let listOfKeys = {}
                for (const [categoryName, subcategories] of Object.entries(facetsLists.packages)) {
                    listOfKeys[categoryName] = []
                    Object.keys(subcategories).forEach((subcategoryName) => {
                        if (!listOfKeys[categoryName].includes(subcategoryName)) {
                            listOfKeys[categoryName].push(subcategoryName)
                        }
                    })
                    Object.keys(facetsLists.content[categoryName]).forEach((subcategoryName) => {
                        if (!listOfKeys[categoryName].includes(subcategoryName)) {
                            listOfKeys[categoryName].push(subcategoryName)
                        }
                    })
                }
                // Combine two objects in one
                for (const [categoryName, subcategories] of Object.entries(listOfKeys)) {
                    allFacets[categoryName] = {}
                    subcategories.forEach((subcategoryName) => {
                        if (Object.prototype.hasOwnProperty.call(facetsLists.packages[categoryName], subcategoryName)) {
                            allFacets[categoryName][subcategoryName] =
                                facetsLists.packages[categoryName][subcategoryName]
                        }
                        if (Object.prototype.hasOwnProperty.call(facetsLists.content[categoryName], subcategoryName)) {
                            if (Object.prototype.hasOwnProperty.call(allFacets[categoryName], subcategoryName)) {
                                allFacets[categoryName][subcategoryName] +=
                                    facetsLists.content[categoryName][subcategoryName]
                            } else {
                                allFacets[categoryName][subcategoryName] =
                                    facetsLists.content[categoryName][subcategoryName]
                            }
                        }
                    })
                }
                this.search.facets.all = allFacets
                for (const [categoryName, subcategories] of Object.entries(this.search.facets.all)) {
                    let orderedFacets = sortObjByKey(subcategories)
                    this.search.facets.all[categoryName] = orderedFacets
                }
            }
        },

        updateProductsData(productsData) {
            for (const [key, value] of Object.entries(productsData.items)) {
                this.search.result[productsData.type].hits.find((hit, arrayId) => {
                    let tempHit = this.search.result[productsData.type].hits[arrayId]
                    if (hit.id === +key) {
                        this.search.result[productsData.type].hits[arrayId] = Object.assign({}, tempHit, value)
                    }
                    return hit.id === +key
                })
            }
            this.search.result[productsData.type].hits.push(false)
            const index = this.search.result[productsData.type].hits.indexOf(false)
            if (index > -1) {
                this.search.result[productsData.type].hits.splice(index, 1)
            }
        },

        updateModelIds(data) {
            this.modelsExtraData.modelIds = data
        },

        changeResetButtonVisibility(visible) {
            this.search.resetButtonVisible = visible
        },

        async changeSortBy(newActiveSortBy) {
            this.updateSortBy(newActiveSortBy)
            await this.getSearchResult(this.search.query)
        },

        async changeFacets(facetData) {
            let activeTab = this.search.facets.active
            facetData.tab = activeTab
            this.updateActiveFacets(facetData)
            await this.getSearchResult(this.search.query)
            if (facetData.category !== "type" && !this.search.resetButtonVisible) {
                this.changeResetButtonVisibility(true)
            }
        },

        async facetsReset() {
            let activeTab = this.search.activeTab
            if (activeTab === "packages") {
                for (const [categoryName] of Object.entries(this.search.facetFilters.packages)) {
                    this.search.facetFilters.packages[categoryName] = []
                }
            } else if (activeTab === "content") {
                for (const [categoryName] of Object.entries(this.search.facetFilters.content)) {
                    this.search.facetFilters.content[categoryName] = []
                }
            }
            this.search.meiliSearchQueryConfig.contents.packages.filter = []
            this.search.meiliSearchQueryConfig.contents.content.filter = []
            if (this.search.facetFilters.all.type.length > 0) {
                this.search.meiliSearchQueryConfig.contents.packages.filter = [
                    `type = '${this.search.facetFilters.all.type[0]}'`,
                ]
                this.search.meiliSearchQueryConfig.contents.content.filter = [
                    `type = '${this.search.facetFilters.all.type[0]}'`,
                ]
            }
            await this.getSearchResult(this.search.query)
            this.changeResetButtonVisibility(false)
        },

        async getSearchResult(newSearchQuery) {
            let newSearchResult

            if (this.search.type === "all") {
                newSearchResult = await this.makeMultiSearchQuery({
                    searchQuery: newSearchQuery,
                    searchConfig: this.search.meiliSearchQueryConfig.contents,
                })
            } else {
                newSearchResult[this.search.type] = await this.makeSingleSearchQuery({
                    searchQuery: newSearchQuery,
                    searchType: this.search.type,
                    searchConfig: this.search.meiliSearchQueryConfig.contents,
                })
            }

            this.updateSearchResult(newSearchResult)
            this.updateFacetsLists({
                packages: newSearchResult.packages.facetDistribution,
                content: newSearchResult.content.facetDistribution,
            })
        },

        async getSearchSuggestions(newSearchQuery) {
            let response = await this.search.meiliClient
                .index("suggestions")
                .search(newSearchQuery, this.search.meiliSearchQueryConfig.suggestions)
            let searchSuggestions = await response
            this.updateSearchQuery(newSearchQuery)
            this.updateSearchSuggestions(searchSuggestions)
            if (this.search.query !== "") {
                await this.getExtraSuggestions(this.search.suggestions.items[0] + " ")
            }
        },

        async getExtraSuggestions(newSearchQuery) {
            let response = await this.search.meiliClient
                .index("suggestions")
                .search(newSearchQuery, this.search.meiliSearchQueryConfig.suggestions)
            let searchSuggestions = await response
            this.updateExtraSuggestions({
                extraSuggestions: searchSuggestions,
                extraQuery: newSearchQuery,
            })
        },

        async getShowMoreResults(showMoreData) {
            let query = this.search.query
            let type = showMoreData.tabName
            let currentSearchResult = this.search.result[type]
            let loadLimit = this.search.showCardsInTab.single
            let loadedItemsLength = currentSearchResult.hits.length
            let searchConfig = {
                limit: loadLimit,
                offset: loadedItemsLength,
            }

            let sortBy = []
            if (this.search.query === "") {
                if (this.search.sortBy.active === "relevance" || this.search.sortBy.active === "date_order")
                    sortBy = ["date_order:desc"]
                if (this.search.sortBy.active === "title") sortBy = ["title:asc"]
            } else {
                if (this.search.sortBy.active === "date_order") sortBy = ["date_order:desc"]
                if (this.search.sortBy.active === "title") sortBy = ["title:asc"]
            }
            sortBy.length > 0 ? (searchConfig.sort = sortBy) : ""

            if (Object.prototype.hasOwnProperty.call(this.search.meiliSearchQueryConfig.contents[type], "filter"))
                searchConfig.filter = this.search.meiliSearchQueryConfig.contents[type].filter

            let showMoreResult = await this.makeSingleSearchQuery({
                searchQuery: query,
                searchType: type,
                searchConfig: searchConfig,
            })
            let payload = {
                type: type,
                hits: showMoreResult.hits,
            }
            this.updateShowMoreResults(payload)
        },

        async makeMultiSearchQuery(payload) {
            let index = 0 // Need to improve index logic in future
            let searchTypesArray = []
            let multiSearchResultObject = {}

            Object.keys(this.search.types).forEach((key) => {
                // Need to remove all except of -> "this.searchType !== 'all'"
                if (key !== "all") searchTypesArray.push(key)
            })

            let multiSearchResult = await Promise.all(
                searchTypesArray.map(async (searchType) => {
                    let searchIndex
                    searchIndex = searchType
                    let sortBy = []
                    if (this.search.query === "") {
                        if (this.search.sortBy.active === "relevance" || this.search.sortBy.active === "date_order")
                            sortBy = ["date_order:desc"]
                        if (this.search.sortBy.active === "title") sortBy = ["title:asc"]
                    } else {
                        if (this.search.sortBy.active === "relevance") delete payload.searchConfig[searchType].sort
                        if (this.search.sortBy.active === "date_order") sortBy = ["date_order:desc"]
                        if (this.search.sortBy.active === "title") sortBy = ["title:asc"]
                    }

                    sortBy.length > 0 ? (payload.searchConfig[searchType].sort = sortBy) : ""
                    let searchResult = await this.makeSingleSearchQuery({
                        searchQuery: payload.searchQuery,
                        searchType: searchIndex,
                        searchConfig: payload.searchConfig[searchType],
                    })
                    return searchResult
                }),
            )

            Object.keys(this.search.types).forEach((key) => {
                // Need to remove all except of -> "this.searchType !== 'all'"
                if (key !== "all") {
                    multiSearchResultObject[key] = multiSearchResult[index]
                    index++

                    if (this.search.activeTab === key && multiSearchResultObject[key].estimatedTotalHits === 0) {
                        this.changeSearchTab("all")
                    }
                }
            })

            return multiSearchResultObject
        },

        async makeSingleSearchQuery(payload) {
            if (!payload.searchConfig.filter) {
                payload.searchConfig.filter = []
            }
            const languageFilter = `language = ${window.globalJs.route.locale}`
            if (!payload.searchConfig.filter.includes(languageFilter)) {
                payload.searchConfig.filter.push(languageFilter)
            }

            let response = await this.search.meiliClient
                .index(payload.searchType)
                .search(payload.searchQuery, payload.searchConfig)
            let data = await response
            let productsData
            let searchType = payload.searchType.split("_")[0]
            if (searchType === "packages" || searchType === "content") {
                productsData = {
                    searchData: payload,
                    resultData: data,
                }
                if (window.location.pathname === this.search.routes.searchPage) {
                    await this.getProductsData(productsData)
                }
            }

            return data
        },

        async getProductsData(payload) {
            let productsQueryData = {
                packages: [],
                content: [],
            }
            let productsIds = payload.resultData.hits.map((product) => {
                return product.id
            })
            let searchType = payload.searchData.searchType.split("_")[0]
            productsQueryData[searchType] = productsIds
            let productsData = await window.globalJs.axios
                .post(this.search.routes.productsData, productsQueryData)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    console.error(error)
                })
            let productsDataForUpdate = {
                type: searchType,
                items: productsData[searchType],
            }
            this.updateProductsData(productsDataForUpdate)
        },

        async setupMeiliSearch() {
            let meilisearchHost = `https://${window.location.hostname}/search/api/`
            if (window.location.host === "localhost" || window.location.host === "127.0.0.1")
                meilisearchHost = "http://127.0.0.1:7700"
            this.search.meiliClient = new MeiliSearch({
                host: meilisearchHost,
                apiKey: this.search.meiliApiKey, // Change api key to public when search development finish
            })
            this.defineMeiliSearchRoute(meilisearchHost)
        },
    },
})
